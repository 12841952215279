import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const location = useLocation();
  const currentURL = `https://tamil-news.in/${location.pathname}`;

  return (
    <>
      <div className="Apps">
        <Helmet>
          <link rel="canonical" href={currentURL} />
          <title>Privacy Policy | Data Protection on www.tamil-news.in</title>
          <meta
            name="description"
            content="Learn how www.tamil-news.in protects your data while streaming live Tamil news. Understand our cookie use, data security, and privacy practices. Stay updated on our policy."
          />
          <meta
            name="keywords"
            content="Privacy Policy, data protection, cookies, personal information, non-personal information, third-party content, news aggregator, external links, children's privacy, live Tamil news, www.tamil-news.in"
          />
        </Helmet>
        <Header />

        <div className="privacy-policy">
          <div className="container">
            <button className="back-arrow" onClick={goBack}>
              <i className="fa fa-long-arrow-left"></i>
            </button>
            <h1>Privacy Policy</h1>
          </div>

          <p>
            At www.tamil-news.in, we respect your privacy and are dedicated to
            protecting your personal information. This Privacy Policy details
            how we collect, use, and safeguard the data you provide when
            accessing our website, which features live streaming of Tamil news
            channels like Sun News, Puthiya Thalaimurai, Thanthi TV, and others.
            By continuing to use our services, you agree to the terms outlined
            in this policy.
          </p>
          <ol className="bullet-list">
            <li>
              <h2>Information Collection</h2>
              <p>
                We collect two types of information to enhance your experience
                with the live news channels:
              </p>
              <ol className="dash">
                <li>
                  <h3>Personal Information</h3>: We do not collect personal
                  details such as names, emails, or phone numbers unless
                  voluntarily provided through contact forms, newsletter
                  subscriptions, or account registration.
                </li>
                <li>
                  <h3>Non-Personal Information</h3>: We automatically gather
                  non-identifiable data, such as IP addresses, browser types,
                  visited pages, and time spent on the site. This information
                  helps us improve the performance of our website and understand
                  user behavior when streaming live news.
                </li>
              </ol>
            </li>
            <li>
              <h3>Cookies and Tracking Tools</h3>
              <p>
                We use cookies and similar tracking technologies to personalize
                your experience while watching live Tamil news streams and to
                analyze traffic. Cookies help us enhance your interaction with
                the website by customizing content and improving functionality.
                You can manage or disable cookies in your browser settings, but
                doing so may limit some functionalities of the live streaming
                services.
              </p>
            </li>
            <li>
              <h2>How We Use Your Data</h2>
              <p>The data we collect is used to:</p>
              <ul className="dash-list">
                <li>
                  Optimize website performance and the live streaming
                  experience.
                </li>
                <li>Personalize your interactions with the content.</li>
                <li>Monitor usage patterns and user preferences.</li>
                <li>
                  Ensure compliance with legal obligations and protect our
                  rights.
                </li>
              </ul>
            </li>
            <li>
              <h2>Intellectual Property & Content Ownership</h2>
              <p>
                All news streams from channels such as [Sun News Live] (
                <a
                  href="https://www.youtube.com/watch?v=9M02G5c6x6w"
                  target="blank"
                >
                  https://www.youtube.com/watch?v=9M02G5c6x6w
                </a>
                ), [Puthiya Thalaimurai Live] (
                <a
                  href="https://www.youtube.com/watch?v=RUzgphgBRzI"
                  target="blank"
                >
                  https://www.youtube.com/watch?v=RUzgphgBRzI
                </a>
                ), [Thanthi TV Live] (
                <a
                  href="https://www.youtube.com/watch?v=j1nzTy5q_1Y"
                  target="blank"
                >
                  https://www.youtube.com/watch?v=j1nzTy5q_1Y
                </a>
                ), [News18 Tamil Nadu Live] (
                <a
                  href="https://www.youtube.com/watch?v=lnHtG9YLqo4"
                  target="blank"
                >
                  https://www.youtube.com/watch?v=lnHtG9YLqo4
                </a>
                ), and others are owned by their respective creators. As a news
                aggregator, we embed live streams from external sources and do
                not claim ownership of third-party content. If you believe any
                material on our site infringes copyright laws, please contact us
                for prompt resolution.
              </p>
            </li>
            <li>
              <h2>External Links</h2>
              <p>
                Our platform may contain links to third-party websites,
                including YouTube channels for live streaming. We are not
                responsible for the content or privacy practices of these
                external sites.
              </p>
            </li>
            <li>
              <h2>Data Protection</h2>
              <p>
                We take appropriate measures to protect the information you
                share with us from unauthorized access, use, or disclosure.
                However, no online transmission is entirely secure, and we
                cannot guarantee absolute security.
              </p>
            </li>
            <li>
              <h3>Children's Privacy</h3>
              <p>
                Our website is not intended for individuals under the age of 13.
                We do not knowingly collect any personal information from
                children. If a parent or guardian believes that their child has
                submitted personal information, please contact us, and we will
                take steps to delete the data promptly.
              </p>
            </li>
            <li>
              <h3>Policy Updates</h3>
              <p>
                We may modify this Privacy Policy periodically to reflect
                changes in our practices or legal obligations. Any updates will
                be posted on this page and will take effect immediately after
                publication.
              </p>
            </li>
            <li>
              <h3>Contact Information</h3>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or the practices of www.tamil-news.in, please contact us at:
                Email: contact@tamil-news.in
              </p>
            </li>
          </ol>
          <p>
            We are a news aggregator, and all content rights remain with the
            original creators. We do not claim ownership of third-party
            materials.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
